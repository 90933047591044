.record-main { background-color: $content-bg; position: relative;
   .record-sidebar { width: 280px; padding: 24px; background: $light-bg; @include flex-shrink(0); }
   .record-section { width: calc(100% - 280px); 
       .inner-section { width: 1024px; max-width: 100%; padding: 48px 16px 16px; @include flex-shrink(0); }
       .marketing-section { display: none;
        &.active { display: block;}
       }
       .record-contact { margin-bottom: 32px; padding: 32px; background: #fff;
            .scrollable-head-list { margin-bottom: 20px; padding-bottom: 20px; height: 90px !important; }
            .head { padding: 12px 16px; min-width: 240px; background: rgba($dark-bg , 0.2); color: #fff; cursor: pointer;
                &.active { background: $dark-bg; }
                img { margin-right: 16px; width: 40px; @include flex-shrink(0); }
            }
            h3 { line-height: 22px;}
            textarea.form-control { height: 150px;}
            .custom-checkbox { padding-left: 10px; width: 150px;
                .custom-control-label { width: auto;}
            }
            .custom-control-label { padding-left: 32px;}
            .switch-group {
                 .custom-control-label { padding-left: 0px;}
            }
            .no-gutters { margin-right: -1px; margin-left: -1px; }
            .no-gutters>[class*=col-] { padding-right: 1px; padding-left: 1px; }
        }
   }
}
.scrollable-contact-list { margin: 16px 0 24px; height: 182px !important;
    .contact { margin-bottom: 10px; padding: 10px; background: $light-medium; font-weight: 500;
        img { margin-right: 6px; width: 36px; @include flex-shrink(0); }
    }
}
.full-btn-list { margin: 16px 0; padding: 16px 0; border-bottom: 1px solid $light-medium; border-top: 1px solid $light-medium;
    button { margin-bottom: 8px;
        &:last-child { margin-bottom: 0; }
    }
}
.record-tab {
    button { margin-bottom: 6px; width: 100%; background: $light-medium; color: inherit; border: none; 
        &.active { background: $theme-color !important; color: #fff;}
    }
}
.add-contact-sidebar { position: absolute; top: 0; width: 0; background: rgba(62, 62, 62, 0.44); z-index: 5; @include transition(0.1s all ease-in-out);
    &.active { width: 100%; }
}

@media (max-width: 1199px) {
    .record-main { 
        .record-section {  
            .record-contact {
                .head { min-width: 220px; }
                h3 { font-size: 19px;}
            }
        }
    }
}

@media (max-width: 991px) {
    .record-main { 
        .record-sidebar { width: 260px; padding: 20px; }
        .record-section { width: calc(100% - 260px); 
            .inner-section { padding: 32px 16px 16px; }
            .record-contact { margin-bottom: 24px; padding: 20px;
                .head { padding: 12px 14px; min-width: 210px; 
                    img { margin-right: 10px; }
                }
                h3 { font-size: 18px;}
            }
        }
    }
}
@media (max-width: 767px) {
    .record-main { 
        .record-sidebar { width: 100%; padding: 20px; }
        .record-section { width: 100%;
            .record-contact { margin-bottom: 20px; padding: 10px;
                .scrollable-head-list { margin-bottom: 10px; padding-bottom: 10px; height: 74px !important; }
                .head { padding: 10px 12px; min-width: 180px; 
                    img { margin-right: 8px; width: 32px; }
                }
                h3 { font-size: 16px; line-height: 18px;}
             }
        }
    }
}
@media (max-width: 575px) {
    .record-main { 
        .record-sidebar { padding: 15px; }
        .record-section {
            .inner-section { padding: 28px 14px 14px; }
            .record-contact { margin-bottom: 20px; padding: 10px;
                textarea.form-control { height: 100px;}
             }
        }
    }
}

@media (max-width: 460px) {
    
}