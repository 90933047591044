.common-side-section { width: 100%; height: $section-height; background: $common-bg; overflow-y: auto;
   .inner-section { padding: 24px 30px; min-height: 100%; width: 550px; background: #fff;}
   .close-btn   { width: 16px; 
        img { width: 100%;}
    }
}
.common-form { margin-bottom: 24px;
    .form-group { margin: 0; background: $light-bg; border-bottom: 2px solid #fff; text-transform: uppercase;
        &:nth-child(even)   { background: $light2-bg; }
        .form-group { background: transparent; border-bottom: none; }
        label   { margin: 0; padding: 8px 15px; width: 180px; @include flex-shrink(0); font-size: inherit;}
        .form-control { padding: 8px 15px 7px; background: transparent; font-size: inherit; border: none; border-left: 2px solid #fff; border-radius: 0; }
        .inner-label { width: inherit; border-left: 2px solid #fff;  }
        textarea.form-control { height: 80px; resize: none; overflow: auto;}
        [type="date"].form-control { height: 36px; }
        .large-input { min-width: 140px; }
        [type="date"].large-input { min-width: 152px; width: 152px; }
        &.switch-group {
            label   { width: auto;@include flex-shrink(1);  }
            .custom-control-label { margin: 8px 15px; padding: 0;}
        }
    }
    .custom-dropdown { width: 100%; 
        .dropdown-toggle { padding: 8px 15px 7px; width: 100%; font-weight: 400; text-align: left; border: none !important; border-left: 2px solid #fff !important; 
            &::after    { float: right; font-size: 14px; line-height: 20px;}
        }
        .dropdown-item { padding: 7px 15px;}
    }
    .succes-btn { margin-top: 16px; }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}
.custom-switch { padding-left: 0px;  @include flex-shrink(0);
    .custom-control-label { padding: 0;
        &::after,
        &::before { top: 0; left: 0; border: none; border-radius: 2em; box-shadow: none !important;}
        &::before { height: 22px; width: 84px; background: $theme-color; }
        &::after    { margin: 2px; height: 18px; width: 40px; background: #fff;}
        span { display: inline-block; width: 40px; color: #fff; font-size: 12px; line-height: 22px; position: relative; z-index: 2; text-align: center;}
    }
    .custom-control-input:checked ~ .custom-control-label::before { background: $danger;}
    .custom-control-input:checked ~ .custom-control-label::after { @include transform(translateX(100%)); }
} 
.record-list{
    .item { padding: 16px 0; border-top: 1px solid $light-bg;
        .desc { margin-bottom: 6px; padding: 12px 16px; background: $light-bg;
            p { margin-bottom: 4px; 
                &:last-child { margin: 0; }
            }
        }
        .succes-btn { margin-top: 8px; }
    }
}
.customer-item {
    .title { padding-left: 15px; background: $dark-bg; color: #fff; }
}
@media (min-width: 1200px) and (max-width: 1300px) {
    .common-form .form-group [type="date"].large-input {
        min-width: 110px;
        width: 110px;
    }
}
@media (max-width: 991px) {
    .common-side-section { height: $section-height-md; }
    .common-form { margin-bottom: 20px;
        .form-group {
            label   { padding: 8px 12px; width: 160px; }
            .form-control { padding: 8px 12px 7px;}
            textarea.form-control { height: 60px; }
            [type="date"].form-control { height: 36px; }
        }
        .custom-dropdown { width: 100%; 
            .dropdown-toggle { padding: 8px 12px 7px; width: 100%; font-weight: 400; text-align: left; border: none !important; border-left: 2px solid #fff !important; 
                &::after    { font-size: 14px; line-height: 20px;}
            }
            .dropdown-item { padding: 7px 12px;}
        }
        .succes-btn { margin-top: 16px; }
        .switch-group {
            .custom-control-label { margin: 8px 12px; padding: 0;}
        }
    }
}
@media (max-width: 767px) {
    .common-side-section { height: $section-height-sm; }
}

@media (max-width: 575px) {
    .common-side-section {
        .inner-section { padding: 24px 30px; width: 100%;}
    }
}

@media (max-width: 480px) {
    .common-side-section {
        .inner-section { padding: 20px 16px; }
    }
    .common-form { margin-bottom: 16px;
        .form-group {
            label   { padding: 6px 12px; width: 152px; }
            .form-control { padding: 6px 12px 5px; width: calc(100% - 152px);
                &.large-input { min-width: inherit; width: calc(100% - 152px); }
            }
            textarea.form-control { height: 70px; }
            [type="date"].form-control { min-width: inherit; height: 29px;}
            &.has-inner-group  { flex-wrap: wrap; -webkit-flex-wrap: wrap; 
                .form-group {
                    label   { width: 152px; border-left: 0; }
                    .inner-label { border: none;}
                }
            }
            .form-group { width: 100%; }
        }
        .custom-dropdown {  
            .dropdown-toggle { padding: 6px 12px 5px;
                &::after    { font-size: 12px; line-height: 18px;}
            }
            .dropdown-item { padding: 6px 12px;}
        }
        .succes-btn { margin-top: 12px; }
    }
}