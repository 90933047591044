.footer { position: fixed; width: calc(100% - 280px); right: 0; bottom: 0; z-index: 4;
  .footer-inner { margin: 0 auto; padding: 0 15px; width: 1200px; max-width: 100%; }
}
.footer-tab { background: #ffc000;
  button { background: transparent; border: none; border-radius: 0; text-transform: uppercase;
    &:not(:disabled):not(.disabled).active,
    &:hover { background: #ffd34d; }
  }
}

.footer-scroll {
  height: 200px !important;
}
.footer-info { display: none; padding: 36px 0; background: #fff; 
  &.active { display: block;}
}
.log-list { margin-top: 24px;
  .item { padding: 6px 16px; background: $light-bg; 
    p { margin: 0; }
    .title { margin-right: 24px; font-weight: 600; @include flex-shrink(0); }
    &:nth-child(even){
      background: $light2-bg;
    }
  }
}
@media (max-width: 991px) { 
  .footer { position: fixed; width: calc(100% - 260px); }
  .footer-info { padding: 20px 0;}
  .log-list { margin-top: 18px; }
}

@media (max-width: 767px) { 
  .footer { width: 100%; }
}

@media (max-width: 575px) { 
  .log-list .item  { @include flex-direction(column);}
}
