$theme-color: #50942a;
$base-color: #404040;
$primary-color: #00a7e5;
$primary-dark: #3d285f;
$light-color: #eaedf3;
$common-bg: #808489;
$light-bg: #f2f2f2;
$light2-bg: #e7e6e6;
$light-medium: #dbdbdb;
$dark-bg: #292929;
$content-bg: #d6dce5;
$danger: #ff5757;
$info-color: #44546a;
$section-height: calc(100vh - 80px);
$section-height-md: calc(100vh - 66px);
$section-height-sm: calc(100vh - 66px);


@mixin transition($property) {
    -webkit-transition: $property;
    -ms-transition: $property;
    transition: $property;
}
@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}
@mixin box-shadow($property) {
    -webkit-box-shadow: $property;
    box-shadow: $property;
}
@mixin filter($property) {
    -webkit-filter: $property;
    filter: $property;
}
@mixin flex-shrink($property) {
    -webkit-flex-shrink: $property;
    flex-shrink: $property;
}
@mixin flex-grow($property) {
    -webkit-flex-grow: $property;
    flex-grow: $property;
}
@mixin flex-direction($property) {
    -webkit-flex-direction: $property;
    flex-direction: $property;
}

%after-before       { content: ""; position: absolute; display: block; }
