@charset "utf-8";
/* CSS Document */

/******* Fonts Face CSS Start **********/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
/********* Fonts Face CSS End **********/

/******* Common Element CSS Start ******/
* 						{ margin: 0px; padding: 0px;}
body 					{ font-family: 'Roboto', sans-serif; font-size: 14px; line-height: 20px; color: $base-color;}
.clear 					{ clear:both;}
img 					{ border: 0px; max-width: 100%;}
ul,ol 					{ list-style: none;}
a 						{ text-decoration: none; outline: none; color: inherit; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out;}
a:focus, a:active, a:visited, a:hover	{ text-decoration: none; outline: none; }
a:hover					{ color: $primary-color; }
h1 						{ margin-bottom: 5px; font-size: 24px; line-height: 32px; font-weight: 600; }
h2 						{ margin-bottom: 5px; font-size: 22px; line-height: 30px;}
h3 						{ margin-bottom: 5px; font-size: 20px; line-height: 28px; }
h4 						{ margin-bottom: 8px; font-size: 16px; line-height: 24px; font-weight: 400; }
p 						{ margin-bottom: 16px; }
.btn,
button 					{ font-size: inherit; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; border-radius: initial; }
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus,
.btn-secondary.focus, .btn-secondary:focus,
button:focus 			{ box-shadow: none; -webkit-box-shadow: none; outline: none;}
.b-border 				{ border-bottom: 1px solid; }
.line-btn.btn			{ padding: 0; background: transparent !important; border: none; color: $primary-dark !important; text-transform: uppercase; border-bottom: 1px solid; font-weight: 600; }
.line-btn.btn:hover,
.line-btn.btn:active 	{ color: $primary-color !important; }
.dropdown-menu 			{ font-size: inherit; line-height: inherit; }
.dropdown-item 			{ font-weight: 500; }
.light-text             { color: #000; opacity: 0.5; }
.bold-text 				{ font-weight: 600; }
b 						{ font-weight: 600; }
/******* Common Element CSS End *********/
/* -------- Custom Elemet Style ------- */
.scroll-track 					{ background: #cdcdd0; z-index: 5; border-radius: 2em; }
.scroll-track .scroll-thumb 	{ background: #a7a7a9; border-radius: 2em; }
.track-vertical 				{ height: 90%; right: 20px; top: 5%; }
.track-horizontal 				{ width: 100%; height: 8px !important; right: 0; top: 0px; 
	.bottom-scroll & 	{ top: calc(100% - 10px); }
}
.custom-dropdown .dropdown-toggle			{ padding: 12px 16px; background: transparent !important; color: $base-color !important; font-weight: 500; text-transform: uppercase; border: 1px solid $light-color !important; border-radius: 0; position: relative; white-space: normal; width: 100%; }
.custom-dropdown .dropdown-menu 			{ padding: 0; background: #fff; width: 100% !important; top: 100% !important; transform: none !important; -webkit-transform: none !important; border-radius: 0; overflow: auto; border: none;}
.custom-dropdown .dropdown-menu .dropdown-item 			{ padding: 12px 15px; background: $light-color; text-transform: uppercase;}
.custom-dropdown .dropdown-menu .dropdown-item.active,
.custom-dropdown .dropdown-menu .dropdown-item:hover 	{ background: $primary-dark; color: #fff; }
.custom-dropdown .dropdown-toggle::after 	{ margin-left: 8px; content: "\f078"; font-size: 18px; line-height: 1; color: rgba(0,0,0,0.3); font-weight: 900; font-family: 'Font Awesome 5 Free'; vertical-align: middle; border: none; }
.custom-dropdown.dropdown .jcf-scrollable-wrapper 			{ margin: 0;}
.modal-body .custom-dropdown .dropdown-toggle 		{ border-radius: 0.25rem; padding: 13px 16px; }
.scrollable-dropdown 						{ height: 226px !important; }
.vertical-scrollable,
.scrollable-dropdown {
	.track-horizontal 		{ display: none;}
}
.horizontal-scrollable {
	.track-vertical { display: none; }
}
.side-scroll { width: calc(100% + 16px) !important;
	> :first-child { padding-right: 16px;}
	.track-vertical { right: 0px; }
}

/* -------- Button Style ------- */
.theme-btn 				{ padding: 14px 34px; min-width: 170px; display: inline-block; color: #fff; background: $primary-color; font-weight: 600; border: none; border-radius: 2em; 
    &:hover,
    &:focus     { background: $base-color; }
}
.succes-btn             { padding: 9px 20px 8px; display: inline-block; min-width: 140px; background: $theme-color; text-transform: uppercase; border: none;
    &:hover,
	&:focus     { background: $primary-dark; }
	&.full-btn { width: 100%; }
	&.rounded-btn { border-radius: 6px;}
	&.black-btn { background: $dark-bg;
		&:hover { background: $theme-color; }
	}
}
.btn-link       { padding: 0; }

/******* Header Section CSS Start *******/
.navbar 				{ padding: 0; border-bottom: 1px solid $light-color; text-transform: uppercase;}
.navbar-brand 			{ padding: 16px 24px; background: $theme-color; }
.navbar-brand img 		{ display: block; height: 47px; }
.navbar .navbar-nav 	{ font-weight: 500; }
.navbar .navbar-nav .nav-link 	{ padding: 0; border-bottom: 4px solid transparent; }
.navbar .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .show .nav-link { background: transparent; color: $theme-color; border-bottom-color: $theme-color; }
.navbar .icon-box 		{ display: block; flex-shrink: 0; padding: 16px 14px 10px; border-right: 1px solid $light-color; position: relative;}
.navbar .icon-box .count 	{ width: 24px; height: 24px; position: absolute; right: 6px; bottom: 8px; font-size: 12px; line-height: 24px; color: #fff; background: $theme-color; border-radius: 50%; text-align: center; }
.navbar li:last-child .icon-box,
.navbar li:first-child .icon-box 			{ border-left: 1px solid $light-color; }
.navbar .icon-box img 						{ height: 48px;}
.navbar .navbar-nav .item-text 				{ padding: 0 16px; display: none; }
.navbar .navbar-nav .item-text b 			{ margin-left: 8px; opacity: 0.4;}
.navbar .navbar-nav .user-item .item-text	{ display: block; }
.navbar-nav .dropdown-menu { margin-top: 16px; padding: 12px 10px; left: 50%; transform: translateX(-50%); -webkit-transform: translateX(-50%); border: none; border-radius: 0; box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); position: relative; }
.navbar-nav .dropdown-menu:before { content: ""; display: block; position: absolute; top: -10px; left: 50%; transform: translateX(-50%); -webkit-transform: translateX(-50%); width: 0; height: 0; border-left: 12px solid transparent; border-right: 12px solid transparent; border-bottom: 10px solid #fff;}
.navbar-nav .dropdown-item { padding: 8px 10px 7px; border-bottom: 1px solid #e7e6e6; text-transform: uppercase; }
.navbar-nav .dropdown-item:hover,
.navbar-nav .dropdown-item.active { background: $theme-color; color: #fff; border-bottom-color: $theme-color;  }
.navbar-nav .dropdown-item:last-child { border: none;}
/******* Header Section CSS End *********/

/******* Middle section CSS Start ******/
.main-content 			{ min-height: $section-height; }
.sidebar 				{ padding: 15px 12px; width: 70px; border-right: 1px solid $light-color; text-align: center; }
.sidebar ul li a 		{ margin-bottom: 15px; display: block; }
.sidebar ul li a img 	{ width: 100%; }
.content-section 		{ width: calc(100% - 70px); padding: 32px 32px 32px 16px; background: #fbfbfd; }

/* -------- Login page ------- */
.login-section 				{ min-height: 100vh; font-family: 'Open Sans', sans-serif; }
.login-section > div 		{ width: 50%; flex-shrink: 1; -webkit-flex-shrink: 1; }
.login-section .form-block	{ padding: 32px 15px; width: 400px; max-width: 45%; min-width: 31%; margin: 0px auto; align-self: center;}
.login-section .info 		{ margin-bottom: 24px; max-width: 340px;}
.form-group 				{ margin-bottom: 18px;}
.form-group label 			{ margin-bottom: 10px;}
.form-control,.cat-dropdown { height: auto; padding: 10px 15px; font-size: 1rem; border-color: #eaeaea; resize: none; }
.form-control:focus,.cat-dropdown:focus { box-shadow: none; -webkit-box-shadow: none; }
#EventDescription.form-control { height: 138px; }
.cat-dropdown 				{ background: url("../images/downarrow.svg") no-repeat right .75rem center/25px 8px; }
.login-section .theme-btn 	{ margin: 10px 0 24px;}
.login-section .img-block 	{ background: #e4e4e4 no-repeat center center / cover; }

/* -------- Home page ------- */
.home-table-wraper 						{ padding-left: 36px; position: relative; }
.home-table-wraper::before 				{ content: ""; position: absolute; width: calc(100% - 376px); height: 28px; background: #F3F3F5; right: 0; top: 16px; border-radius: 4px 4px 0 0; }
.home-table 							{ position: relative; }
.scrollable-table 						{ width: 100%; height: calc(100vh - 144px) !important; }
.scrollable-table > div:first-child		{ margin-top: 24px; }
.scrollable-table .track-horizontal 	{ width: calc(100% - 380px) !important; right: 20px; top: 8px; background: #fff; }
.scrollable-table .scroll-track .scroll-thumb { background: #424242; }

.scrollable-table 						{ height: calc(100vh - 144px); position: relative; overflow: auto; }
.scrollable-table .track-vertical		{ right: 0;}
.theme-table 							{ display: table; background: #fff; font-weight: 500; border: 1px solid $light-color; box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.04); border-radius: 4px; position: relative; }
.theme-table .collapse-row > div > ul > li,
.theme-table > li						{ display: flex; display: -webkit-flex; position: relative;}
.theme-table li:last-child				{ border: none; }
.theme-table li > div 					{ padding: 11px 16px; min-width: 17vw; max-width: 17vw; background: #fff; color: #3e3f42; flex-shrink: 0; -webkit-flex-shrink: 0; text-align: center; border-bottom: 1px solid $light-color; border-right: 1px solid $light-color; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; }
.theme-table > ul > li:last-child > div { border: none;}
.theme-table li > div:last-child     	{ border-right: unset; }
.theme-table li > div:first-child 		{ position: sticky; position: -webkit-sticky; left: 0; padding-left: 30px; padding-right: 40px; min-width: 340px; max-width: 340px; color: #9ea0a5; text-transform: uppercase; text-align: left; z-index: 2; }
.theme-table .table-head 				{ position: sticky; top: 0px; left: 0px; z-index: 5;}
.theme-table .table-head > div 			{ padding-top: 32px; padding-bottom: 32px; display: flex; display: -webkit-flex; align-items: center; -webkit-align-items: center; justify-content: center; -webkit-justify-content: center; }
.theme-table .table-head > div:first-child { font-size: 18px; line-height: 24px; color: #3e3f42; justify-content: start; -webkit-justify-content: start; }
.theme-table .table-head img 			{ max-height: 75px; width: auto; max-width: 90%; }
.theme-table .input-row .form-control	{ padding-left: 0; padding-right: 0; text-align: center; }
.theme-table .input-row > div:first-child .form-control { text-align: left;}
.theme-table .close-toggle 				{ padding: 0; display: inline-block; background-color: transparent !important; height: 16px; width: 16px; position: absolute; right: 20px; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); opacity: 0.4; background: transparent url(../images/plus-icon.png) no-repeat center center / cover; border: none; }
.theme-table .close-toggle.active		{ transform: translateY(-50%) rotate(45deg); -webkit-transform: translateY(-50%) rotate(45deg); filter: brightness(0) invert(1); -webkit-filter: brightness(0) invert(1); opacity: 1; }
.theme-table .right-icon 				{ max-width: 22px; }
.theme-table .umbrella-icon 			{ max-width: 22px; float: right;  margin-left: -22px; }
.theme-table li.collapse-row > div:first-child 	{ min-width: initial; max-width: initial; padding: 0; position: relative; border: none; }
.theme-table .full-btn 					{ position: absolute; z-index: 4; min-width: 100%; height: 100%; top: 0; left: 0; opacity: 0;}
.theme-table .desc li,
.theme-table .desc p 					{ margin-bottom: 16px; text-align: left; font-weight: 400; text-transform: initial;}
.theme-table .desc li::before			{ content: ""; margin: -2px 4px 0 0; display: inline-block; width: 4px; height: 4px; background: #3e3f42; vertical-align: middle; border-radius: 50%; }
.theme-table .gray-toggle 				{ position: absolute; left: 0; top: 0; height: 100%; width: 100%; }
.table-btns 							{ width: 36px; height: 0; position: sticky; position: -webkit-sticky; top: 230px; left: 0px; z-index: 6;  transform: translateX(-100%); -webkit-transform: translateX(-100%); }
.table-btns button 						{ height: 36px; width: 100%; background: #fff; border: 1px solid $light-color;  border-radius: 0; position: relative;}
.table-btns button::before				{ content: ""; display: block; height: 46%; width: 46%; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); -webkit-transform: translate(-50%,-50%); opacity: 0.4; background: transparent url(../images/plus-icon.png) no-repeat center center / cover; }
.table-btns .add-row::before			{ background-image: url(../images/plus-icon.png); }
.table-btns .gen-pdf::before			{ background-image: url(../images/pdf-icon.png); }
.table-btns button.btn-secondary:not(:disabled):not(.disabled).active,
.table-btns button:hover 				{ background: $primary-color; border-color: #fff; }
.table-btns button.active::before,
.table-btns button:hover::before 		{ filter: brightness(0) invert(1); -webkit-filter: brightness(0) invert(1); opacity: 1; }
/* Row Background */
.theme-table .theme-table li > div 								{ background: #21a6f4; color: #fff; }
.theme-table .dark-blue > div:first-child,
.theme-table .dark-blue > div 									{ background: $primary-dark; color: #fff; }
.theme-table .dark-blue > div:nth-child(even) 					{ background: #372455; }
.theme-table .toggle-row .cond-def-row > div 					{ background: #fff; color: #3e3f42;}
.theme-table .collapse-row > div > ul > li > div { background: #21a6f4; color: white; }
/* -------- EVENTS COVERED BY CATEGORY  ------- */
.theme-table li > div.cat-title-box 	    					{ min-width: 100% !important; max-width: 100% !important; background: transparent;  }
.theme-table li > div.cat-title-box .cat-table-title			{ margin: 18px 0; color: #3e3f42; }
.cat-table-title 												{ margin: 18px 0px 18px 36px; width: 320px; max-width: 100%; position: sticky; position: -webkit-sticky; left: 30px; font-weight: 500; }
// .cat-table .theme-table > li.open-collapse + li 				{ margin-bottom: 32px; }
.cat-table .theme-table li.collapse-row > div:first-child 		{ background: transparent; }
.theme-table .collapse-row li.cat-row > div 	 			    { background: #fff; color: #3e3f42;}
.theme-table .collapse-row li.cat-row > div:first-child 		{ background: #29ABE2; color: #fff; padding-top: 50px; text-align: center; }
.theme-table .collapse-row li.cat-row > div img 			    { max-width: 50%; }
// div.condition-toggle-desc.collapse.show 						{ background: #fff !important; color: black !important; }
// condition list
.theme-table .toggle-row li .condition-list > li 	{ padding: 13px 16px; margin-bottom: 18px; text-align: left; background: #F2F2F2; border-radius: 10px; }
.theme-table .toggle-row li .condition-list > li:last-child { margin-bottom: 0; }
.theme-table .toggle-row li .condition-list > li.yellow-bg { background: #FFD966; }
.theme-table .tiles-bg .btn-link 					{ padding-right: 16px; width: 100%; color: inherit; font-weight: 500; text-align: left; text-decoration: none !important; position: relative; }
.theme-table .tiles-bg .btn-link::after 				{ content: ""; position: absolute; right: -2px; top: 8px; width: 14px; height: 14px; background: url(../images/triangular-arrow-down.png) no-repeat center center / cover; }
.theme-table .tiles-bg .btn-link.active::after 	    { background-image: url(../images/close-icon.png); }
.theme-table .tiles-bg .condition-toggle-desc 	 	{ width: 100%; min-width: 100%; margin-top: 8px; background: #fff !important; color: black !important; padding: 16px; border-radius: 8px; text-transform: initial; font-weight: 400; font-size: 12px; line-height: 16px; text-align: left; }
.theme-table .toggle-row li p.capital 				{ text-transform: uppercase; }
.theme-table .toggle-row li p 						{ text-transform:initial; margin: 0; }
// payment tickmark list
.payment-list li										{  position: relative; text-transform: initial; }
.payment-list li::before 								{ content: ""; width: 13px; height: 10px; background: url(../images/right-arrow-white.png) center center / cover no-repeat; display:inline-block; margin-right : 4px; }
.payment-list li	p.small-font 						{ font-size: 11px; line-height: 16px; }
/* -------- Filter Section 5/6 screen  ------- */
.open-full-section					{ overflow: hidden; }
.full-section 						{ display: none; position: absolute; width: 100%; height: $section-height; left: 0; top: 80px; background: rgba(61, 40, 95, 0.88); z-index: 11; overflow: auto;}
.full-section.active 				{ display: block; }
.full-section .tab-section 			{ max-width: 740px; min-height: $section-height; background: #fff;}
.full-section .tab-head 			{ padding: 0 24px 0 6px; background: $light-color; position: relative; }
.full-section .tab-head .close-btn 	{ width: 22px; padding: 0; transform: rotate(45deg); -webkit-transform: rotate(45deg); background: transparent !important; box-shadow: transparent; border: none; }
.full-section .nav-pills 			{ width: calc(100% - 24px); flex-wrap: nowrap; white-space: nowrap; overflow-x: auto; overflow-y: hidden;}
.full-section .nav-pills a 			{ margin: 0 20px; padding: 16px 0 12px; background: transparent; color: #101010; font-weight: 400; border: none; border-bottom: 4px solid transparent; border-radius: 0; text-transform: uppercase;}
.full-section .nav-pills a.active 	{ background: transparent; color: #101010; font-weight: 500; border-bottom-color: $primary-dark;}
.tab-content 						{ padding: 20px 24px; }
.check-list  						{ margin: 0;}
.check-list li 						{ padding: 12px; border: 1px solid $light-color; border-bottom: none; font-weight: 500; transition: all 0.2s ease-in-out; -webkit-transition: all 0.2s ease-in-out;}
.check-list li:hover,
.check-list li.active 				{ background: $light-bg; }
.check-list li:last-child 			{ border-bottom: 1px solid $light-color;}
.check-list .form-group label,
.check-list .form-group 			{ margin: 0; }
.custom-control 					{ padding: 0; min-height: auto;}
.check-list .form-group 			{ padding: 0; flex-grow: 1; -webkit-flex-grow: 1; min-height: initial; }
.custom-control .custom-control-label 				{ padding-left: 32px; width: 100%; }
.custom-control .custom-control-label::before 		{ height: 24px; width: 24px; border-color: #d8dce6; top: 50%; left: 0; transform: translateY(-50%); -webkit-transform: translateY(-50%); border-radius: 4px; box-shadow: 0px 2px 2px rgba(0,0,0,0.05); -webkit-box-shadow: 0px 2px 2px rgba(0,0,0,0.05); }
.custom-control-input:checked~.custom-control-label::before { border-color: $primary-dark; background: $primary-dark url(../images/check-icon.png) no-repeat center center / 60% auto; box-shadow: 0px 2px 2px rgba(0,0,0,0.05); -webkit-box-shadow: 0px 2px 2px rgba(0,0,0,0.05); }
.custom-control .custom-control-input:indeterminate~.custom-control-label::before { background: #fff; border-color: #d8dce6; }
.custom-control .custom-control-label::after 	{ display: none; }
.check-list li .delete-icon 		{ margin-right: 48px; width: 20px; flex-shrink: 0; -webkit-flex-shrink: 0; }
.jcf-radio,
.jcf-checkbox 						{ margin-right: 6px; height: 24px; width: 24px; border-color: #d8dce6; border-radius: 4px; box-shadow: 0px 2px 2px rgba(0,0,0,0.05); -webkit-box-shadow: 0px 2px 2px rgba(0,0,0,0.05);  }
.jcf-radio.jcf-checked,
.jcf-checkbox.jcf-checked 			{ border-color: $primary-dark; background: $primary-dark url(../images/check-icon.png) no-repeat center center / 60% auto; }
.scrollable-list 					{ margin-bottom: 36px; height: 296px !important; overflow: auto;}
.scrollable-list .track-horizontal 	{ display: none;}
.sub-title 							{ margin-bottom: 16px;}
.sub-title button					{ padding: 0; background: none !important; color: $primary-dark; font-weight: 500; text-transform: uppercase; border: none; border-bottom: 1px solid; box-shadow: none !important; -webkit-box-shadow: none !important; }
.sub-title button:active,
.sub-title button:hover 			{ color: $primary-color; border-color: $primary-color; }
.selected-list 						{ margin: 0;}
.selected-list li 					{ margin-bottom: 6px; padding: 12px 16px; background: $light-bg; color: #3e3f42; font-weight: 500; border: 1px solid $light-color; }
.selected-list li:last-child 		{ margin-bottom: 0;}
.selected-list li .delete-icon 		{ margin-right: 48px; width: 20px; }

/* -------- Modal Block 7/8/3 screen------- */
.modal-dialog 						{ margin: 0px auto; display: flex; display: -webkit-flex; min-height: 100%; max-width: 70%; width: 800px; align-items: center; }
.modal .action-button button 		{ margin-left: 48px; padding: 0; background: transparent !important; color: $primary-dark; font-weight: 500; border: none; border-bottom: 1px solid; text-transform: uppercase;}
.modal .action-button button:hover,
.modal .action-button button:focus 	{ color: $primary-color !important; }
.modal-content 						{ padding: 36px 36px 24px; }
.modal-body 						{ padding: 0;}
.error-text 						{ margin-top: 24px; color: #e9322f;}
.add-provider-modal .logo-preview 	{ margin-bottom: 8px; width: 160px; height: 160px; background: #faf5f5 no-repeat center center / 100% auto; border: 1px solid $light-color;}
.add-provider-modal .custom-file 				{ display: inline-block; width: 160px; height: auto; }
.add-provider-modal .custom-file-input 			{ position: absolute; height: 100%; cursor: pointer; z-index: 2;}
.add-provider-modal .custom-file-label 			{ margin: 0; padding: 11px 29px; width: 100%; height: auto; background: $primary-dark; color: #fff; border-radius: 0; text-align: center;	position: relative; text-transform: uppercase; font-style: italic; border: none;}
.add-provider-modal .custom-file:hover .custom-file-label 	{ background: $primary-color;}
.add-provider-modal .custom-file-label::after 	{ display: none;}

/* -------- Edit Section 2/3 screen  ------- */
.common-input-list li					{ padding: 8px 16px; color: #9ea0a5; font-weight: 500; text-transform: uppercase; border: 1px solid $light-color; border-bottom: none; border-radius: 0;  position: relative; }
.common-input-list li:last-child		{ border-bottom: 1px solid $light-color;}
.common-input-list li .form-group 		{ margin: 0; }
.common-input-list li.condition-selected { margin-bottom: 12px; color: #495057; border-bottom: 1px solid #eaedf3; }
.edit-section .common-input-list.common-inputs li:not(.condition-selected) { border-bottom: none;} 
.new-provider							{ margin: 0; padding: 12px 36px 12px 15px; background: #fff; position: sticky; position: -webkit-sticky; top: 0; }
.custom-dropdown .dropdown-menu .new-provider a { padding: 0; background: transparent; border-bottom: 1px solid;}
.custom-dropdown .dropdown-menu .new-provider a:hover { color: $primary-color; }
.common-input-list li label 			{ padding: 4px 0; margin: 0; flex-shrink: 0; -webkit-flex-shrink: 0;}
.common-input-list .dropdown 			{ position: static; }
.common-input-list .dropdown-toggle 	{ padding: 0; background: transparent !important; color: $primary-dark !important; border: none !important; font-weight: 700; }
.common-input-list .form-control 		{ padding: 0; font-size: inherit; text-transform: uppercase; text-align: right; font-weight: 900; border: none; border-radius: 0; }
.common-inputs.common-input-list .form-control 		{ padding-right: 24px; padding-left: 4px; }
.common-inputs.common-input-list input[type="date"].form-control { padding-right: 4px; }
.common-accordion 						{ margin-bottom: 20px;  }
.common-accordion .card					{ border-radius: 0; border: none; }
.common-accordion .card-link 			{ margin: 0; padding: 12px 36px 12px 16px; background: transparent; width: 100%; display: block; color: #9ea0a5; font-weight: 500; text-transform: uppercase; border: 1px solid $light-color; border-bottom: none; border-radius: 0;  position: relative; text-align: left;}
.common-accordion .card-header 			{ padding: 0; background: transparent; border-radius: 0; border: none; }
.common-accordion > .card > .card-header > .card-link:active,
.common-accordion > .card > .card-header > .card-link.active 	{ background: $primary-dark; color: #fff;}
.common-accordion .card-link:after 				{ content: ""; height: 16px; width: 16px; position: absolute; right: 16px; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); opacity: 0.4; background: transparent url(../images/plus-icon.png) no-repeat center center / cover; transition: all 0.2s ease-in-out; -webkit-transition: all 0.2s ease-in-out; }
.common-accordion .card-link.active:after 		{ transform: translateY(-50%) rotate(45deg); -webkit-transform: translateY(-50%) rotate(45deg); filter: brightness(0) invert(1); -webkit-filter: brightness(0) invert(1); opacity: 1;}
.common-accordion .card:last-child .card-link 	{ border-bottom: 1px solid $light-color;}
.common-accordion .inner-collapse 				{ padding: 16px 20px;}
.common-accordion .inner-collapse .card-header 	{ position: relative;}
.common-accordion .inner-collapse .card-link 					{ padding-left: 48px; }
/* .common-accordion .inner-collapse .card.active .card-link,
.common-accordion .inner-collapse .jcf-checked ~ .card-link 	{ pointer-events: initial; } */
.common-accordion .inner-collapse .card-link:active,
.common-accordion .inner-collapse .card-link.active 			{ background: transparent; color: #9ea0a5; border-color: $light-color;}
.common-accordion .inner-collapse .card-link.active:after 		{ filter: none; -webkit-filter: none; opacity: 0.4; }
.common-accordion .inner-collapse .custom-control 				{ position: absolute; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); left: 15px; z-index: 5; }
.common-accordion .inner-collapse .custom-control-label 		{ padding: 0;}
.common-accordion .inner-collapse .custom-control .custom-control-label::before { position: static; transform: none; -webkit-transform: none;}
.common-accordion .inner-collapse .common-input-list .custom-control { left: auto; right: 12px;}
.common-accordion .scrollable-inner-accordion					{ margin-bottom: 32px; height: 630px !important; width: calc(100% + 16px) !important; }
.common-accordion .scrollable-inner-accordion > div:first-child { padding-right: 16px;}
.common-accordion .scrollable-inner-accordion.long-scroll 		{ height: 560px !important; }
.common-accordion .scrollable-inner-accordion.demo 			    { height: initial !important; }
.scrollable-inner-accordion .track-vertical 	{ right: 0px;}
.scrollable-inner-accordion .track-horizontal 	{ display: none; }
.inner-accordion .card.active				{ border: 2px solid $primary-dark; border-radius: 3px; }
.scrollable-simple-list						{ margin: 0; height: 230px !important;}
.scrollable-simple-list .track-horizontal 	{ display: none;}
.simple-list li 							{ padding: 12px 24px; color: #9ea0a5; font-weight: 500; text-transform: uppercase; border: 1px solid $light-color; border-bottom: none;}
.simple-list li:last-child 					{ border-bottom: 1px solid $light-color;}
.scrollable-textarea 						{ margin: 12px 0 16px; height: 200px !important; background: $light-color; }
.scrollable-textarea .track-horizontal 		{ display: none;}
.scrollable-textarea .track-vertical 		{ background: #fff; right: 20px; }
.common-input-list textarea.form-control 	{ padding: 12px 40px 12px 12px; background: transparent; display: block; border: none; font-weight: 400; text-align: left; text-transform: none; }
.selected-edit-contract 					{ margin: 24px 0; }
.selected-edit-contract .dropdown-toggle 	{ width: 100%; text-align: left; }

/* -------- Provider Section screen  ------- */
.provider-logo 						{ margin-bottom: 24px; }
.provider-logo img					{ width: 160px; padding: 12px; border: 1px solid $light-color; }
.provider-logo .custom-file 		{ width: auto; height: initial; cursor: pointer;}
.provider-logo .custom-file-input 	{ position: absolute; height: 100%; }
.provider-logo .custom-file-label 	{ margin: 0; padding: 0; position: relative; height: auto; color: $primary-dark; border: none; border-bottom: 1px solid $primary-dark; border-radius: 0; font-weight: 500;}
.provider-logo .custom-file:hover .custom-file-label { color: $primary-color; border-color: $primary-color; }
.provider-logo .custom-file-label::after { display: none;}

/* -------- User Section screen  ------- */
.user-section .tab-section 			{ margin-left: auto;}
.user-list li 						{ padding: 14px 0; color: #9ea0a5; border: 1px solid $light-color; border-bottom: none; font-weight: 500; transition: all 0.2s ease-in-out; -webkit-transition: all 0.2s ease-in-out;}
.user-list li:hover					{ background: $light-bg; }
.user-list.user-list-head li		{ background: $primary-dark; color: #fff; }
.user-list li:last-child 			{ border-bottom: 1px solid $light-color; }
.user-list li > div					{ padding: 0 4px; width: calc(100% / 3); flex-shrink: 0; -webkit-flex-shrink: 0; }
.user-section .bottom-section button 			{ margin-right: 32px; }
.user-section .bottom-section button:last-child { margin-right: 0; }
.selected-user  					{ background: $light-bg; }

/* -------- Error Section screen  ------- */
.error-section 			{ padding: 40px 0; }
.error-section h1 		{ margin-bottom: 32px; font-weight: 500;}
.error-section h4 		{ margin: 0px 0px 0px 4px; display: inline-block; font-weight: 500; }
.error-section img 		{ margin-bottom: 16px; }
.error-section.not-found-section h4 { margin-bottom: 32px; display: block; font-weight: 500; }

.loader-container 						{ position: fixed; height: 100%; width: 100%; top: 0; left: 0; z-index: 1111; }
.custom-loader 							{ display: inline-block; position: relative; width: 80px; height: 80px; }
.custom-loader div 						{ animation: custom-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; transform-origin: 40px 40px; }
.custom-loader div:after 				{ content: " "; display: block; position: absolute; width: 7px; height: 7px; border-radius: 50%; background: $primary-color; margin: -4px 0 0 -4px; }
.custom-loader div:nth-child(1) 		{ animation-delay: -0.036s; }
.custom-loader div:nth-child(1):after 	{ top: 63px; left: 63px; }
.custom-loader div:nth-child(2) 		{ animation-delay: -0.072s; }
.custom-loader div:nth-child(2):after 	{ top: 68px; left: 56px; }
.custom-loader div:nth-child(3) 		{ animation-delay: -0.108s; }
.custom-loader div:nth-child(3):after 	{ top: 71px; left: 48px; }
.custom-loader div:nth-child(4) 		{ animation-delay: -0.144s; }
.custom-loader div:nth-child(4):after 	{ top: 72px; left: 40px; }
.custom-loader div:nth-child(5) 		{ animation-delay: -0.18s; }
.custom-loader div:nth-child(5):after 	{ top: 71px; left: 32px; }
.custom-loader div:nth-child(6) 		{ animation-delay: -0.216s; }
.custom-loader div:nth-child(6):after 	{ top: 68px; left: 24px; }
.custom-loader div:nth-child(7) 		{ animation-delay: -0.252s; }
.custom-loader div:nth-child(7):after 	{ top: 63px; left: 17px; }
.custom-loader div:nth-child(8) 		{ animation-delay: -0.288s; }
.custom-loader div:nth-child(8):after 	{ top: 56px; left: 12px; }
@keyframes custom-loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.claim-radio-group {
	
}
.inner-collapse .claim-radio-group .custom-control { position: static; transform : none; -webkit-transform : none }
.common-input-list .border-input .form-control { border: 1px solid #e4e4e4; border-radius: 4px;  margin-left: 8px; padding: 4px; }

.is-capped > * { flex:1;webkit-flex:1 }
#editUser .form-control:disabled { background-color : #fff }
/******** Middle section CSS End *******/

/***** Footer section CSS Start *******/
/****** Footer section CSS End *******/

/***** responsive css Start ******/

@media (max-width: 1500px) {
/* -------- Home page ------- */
	.theme-table li > div 				{ min-width: 256px; max-width: 256px; }
}

@media (min-width: 1200px) and (max-width: 1399px) {
	body 					{ font-size: 13px; line-height: 19px; }
/******* Header Section CSS *******/
	.navbar .icon-box 			{ padding: 18px 14px 14px; }
	.navbar .icon-box img 		{ height: 44px; }
/* -------- Home page ------- */
	.scrollable-table .track-horizontal { width: calc(100% - 340px) !important; }
	.home-table-wraper::before 			{ width: calc(100% - 336px); }
	.theme-table li > div 				{ padding: 10px 12px; }
	.theme-table li > div:first-child 	{ padding-left: 24px; padding-right: 32px; min-width: 300px; max-width: 300px; }
	.theme-table .table-head > div:first-child { font-size: 17px; line-height: 23px; }
	.theme-table .desc li, 
	.theme-table .desc p 				{ margin-bottom: 14px;}
/* -------- Filter Section 5/6 screen  ------- */
	.full-section .tab-section 			{ max-width: 700px;}

}

@media (max-width: 1199px) {

}	

@media (min-width: 992px) and (max-width: 1199px) {
	body 					{ font-size: 12px; line-height: 19px; }
/******* Header Section CSS *******/
	.navbar .icon-box 			{ padding: 20px 12px 16px; }
	.navbar .icon-box img 		{ height: 40px;}
/* -------- Home page ------- */
	
	.scrollable-table .track-horizontal { width: calc(100% - 320px) !important; }
	.home-table-wraper::before 			{ width: calc(100% - 316px); }
	.theme-table li > div 				{ padding: 9px 11px; min-width: 244px; max-width: 244px; }
	.theme-table li > div:first-child 	{ padding-left: 20px; padding-right: 28px; min-width: 280px; max-width: 280px; }
	.theme-table .table-head > div:first-child { font-size: 16px; line-height: 22px; }
	.theme-table .close-toggle 			{ height: 14px; width: 14px; right: 16px;}
	.theme-table .umbrella-icon,
	.theme-table .right-icon 			{ max-width: 20px; }
	.theme-table .umbrella-icon         { margin-left: -20px; }
	.theme-table .desc li, 
	.theme-table .desc p 				{ margin-bottom: 12px;}
/* -------- Filter Section 5/6 screen  ------- */
	.full-section .tab-section 			{ max-width: 640px;}
	.full-section .tab-head .close-btn 	{ width: 20px;}
	.scrollable-list 					{ height: 264px !important; }
	.check-list li 						{ padding: 9px 12px;}
	.selected-list li 					{ margin-bottom: 6px; padding: 10px 15px;}
	.selected-list li .delete-icon 		{ margin-right: 40px; width: 18px;}
/* -------- User Section screen  ------- */
	.user-list li 						{ padding: 11px 0; }
/* -------- Provider Section screen  ------- */
	.provider-logo img					{ width: 140px; padding: 10px; }
}

@media (max-width: 991px) {
	
}

@media (min-width: 768px) and (max-width: 991px) {
	body 					{ font-size: 12px; line-height: 18px; }
	.scrollable-dropdown 	{ height: 216px !important;}
/******* Header Section CSS *******/
	.navbar-brand 			{ padding: 14px 12px;  }
	.navbar-brand img 		{ height: 38px; }
	.navbar .icon-box 		{ padding: 17px 6px 13px; }
	.navbar .icon-box .count 	{ width: 18px; height: 18px; right: 4px; bottom: 8px; font-size: 10px; line-height: 18px; }
	.navbar .icon-box img 	{ height: 32px; }
	.navbar .navbar-nav .item-text { padding: 0 8px;}
/******* Middle section CSS Start ******/
	.sidebar 				{ padding: 15px 8px; width: 54px; }
	.sidebar ul li a 		{ margin-bottom: 13px; display: block; }
	.content-section 		{ padding: 32px 24px 16px 14px; width: calc(100% - 54px); }
	.main-content 			{ min-height: $section-height-md; }
	.full-section 			{ height: $section-height-md; top: 66px;}
/* -------- Home page ------- */
	.home-table-wraper 					{ padding-left: 32px; }
	.scrollable-table 					{ height: calc(100vh - 132px) !important; }
	.scrollable-table .track-horizontal { width: calc(100% - 320px) !important; }
	.home-table-wraper::before 			{ width: calc(100% - 316px); }
	.table-btns				{ width: 32px; top: 210px; }
	.table-btns button 		{ height: 32px; }
	.theme-table li > div 				{ padding: 9px 11px; min-width: 220px; max-width: 220px; }
	.theme-table li > div:first-child 	{ padding-left: 20px; padding-right: 28px; min-width: 280px; max-width: 280px; }
	.theme-table .table-head > div 		{ padding-top: 24px; padding-bottom: 24px; }
	.theme-table .table-head img 		{ max-height: 60px;}
	.theme-table .table-head > div:first-child 	{ font-size: 16px; line-height: 22px; }
	.theme-table .input-row .form-control 		{ padding: 6px 0;}
	.theme-table .close-toggle 			{ height: 14px; width: 14px; right: 16px;}
	.theme-table .umbrella-icon,
	.theme-table .right-icon 			{ max-width: 18px; }
	.theme-table .umbrella-icon         { margin-left: -18px }
	.theme-table .desc li, 
	.theme-table .desc p 				{ margin-bottom: 10px;}
/* -------- Filter Section 5/6 screen  ------- */
	.full-section .tab-section 			{ max-width: 580px;}
	.full-section .nav-pills a 			{ margin: 0 16px; padding: 12px 0 10px;}
	.full-section .tab-head .close-btn 	{ width: 18px;}
	.scrollable-list 					{ height: 252px !important; }
	.check-list li 						{ padding: 10px 12px;}
	.check-list li .delete-icon 		{ margin-right: 36px; width: 18px; }
	.selected-list li 					{ padding: 10px 14px;}
	.selected-list li .delete-icon 		{ margin-right: 36px; width: 17px; }
/* -------- Modal Block 7/8/3 screen------- */
	.modal-content 						{ padding: 26px 26px 20px;}
	.modal .action-button button 		{ margin-left: 36px; }
	.add-provider-modal .logo-preview 	{ width: 130px; height: 130px; }
	.add-provider-modal .custom-file 	{ width: 130px;}
/* -------- Edit Section 2/3 screen  ------- */
	.common-input-list li 							{ padding: 6px 14px;}
	.common-input-list li.condition-selected        { padding: 8px 14px; }
	.common-accordion .inner-collapse .card-link 	{ padding-left: 42px;}
	.custom-control .custom-control-label 			{ padding-left: 28px; }
	.custom-control .custom-control-label::before 	{ margin-right: 4px; height: 20px; width: 20px;}
	.common-accordion .card-link 					{ padding: 10px 34px 10px 16px;}
	.common-accordion .scrollable-inner-accordion 	{ height: 450px !important;}
/* -------- User Section screen  ------- */
	.user-list li 						{ padding: 11px 0; }
/* -------- Provider Section screen  ------- */
	.provider-logo img					{ width: 120px; padding: 10px; }
/* -------- EVENTS COVERED BY CATEGORY  ------- */
	.cat-table .theme-table .toggle-row li .condition-list > li { padding: 8px 14px; }
}

@media (max-width: 767px) {
	body 								{ font-size: 12px; line-height: 18px; }
	.track-horizontal 					{ height: 6px !important;}
	.track-vertical 					{ right: 15px;}
	.scrollable-dropdown 				{ height: 178px !important; }
	.custom-dropdown .dropdown-menu .dropdown-item 	{ padding: 8px 15px;}
	.custom-dropdown .dropdown-toggle::after 		{ font-size: 14px;}
	.modal-body .custom-dropdown .dropdown-toggle { padding: 11px 16px; }
/* -------- Button Style ------- */
	.theme-btn 					{ padding: 11px 32px; min-width: 150px; }
	.succes-btn             	{ padding: 8px 16px 7px; min-width: 120px; }
/******* Header Section CSS Start *******/
	.navbar-brand 			{ padding: 10px 18px;}
	.navbar-brand img 		{ height: 38px; }
	.navbar-toggler         { margin-right: 16px; padding: 0; height: 28px; width: 28px; border-radius: 0; position: relative; }
    .navbar-toggler-icon    { display: block; width: 20px; height: 3px; background-color: $primary-dark; border-radius: 4px; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; }
    .navbar-toggler .navbar-toggler-icon    					{ margin: 0px !important; width: 100%; background: #141414; position: absolute; top: calc(50% - 1px); transition: all 0.2s ease-in; -webkit-transition: all 0.2s ease-in;  }
    .navbar-toggler .navbar-toggler-icon:nth-child(1)           { top: calc(50% - 8px); }
    .navbar-toggler .navbar-toggler-icon:nth-child(4)           { top: calc(50% + 6px); }
    .navbar-toggler.active .navbar-toggler-icon:nth-child(2)    { transform: rotate(45deg); }
    .navbar-toggler.active .navbar-toggler-icon:nth-child(3)    { transform: rotate(-45deg); }
    .navbar-toggler.active .navbar-toggler-icon:nth-child(1),
    .navbar-toggler.active .navbar-toggler-icon:nth-child(4)    { top: calc(50% - 1px); opacity: 0; }
	.navbar-collapse 		{ padding: 0; position: absolute; width: 100%; left: 0; top: calc(100% + 1px); background: #fff; z-index: 1111; }
	.navbar .icon-box 		{ min-width: 32px; margin-right: 12px; padding: 8px 0px 4px; }
	.navbar .icon-box .count 	{ width: 18px; height: 18px; right: -4px; bottom: 4px; font-size: 10px; line-height: 18px; }
	.navbar .icon-box img 	{ height: 32px; }
	.navbar li:last-child .icon-box,
	.navbar .icon-box,
	.navbar li:first-child .icon-box { border: none; }
	.navbar .navbar-nav .nav-link 	{ padding: 0 15px; border-bottom: 1px solid $light-color; }
	.navbar .navbar-nav .item-text 	{ padding: 0; display: block;}


/******* Middle section CSS Start ******/
	.main-content 			{ min-height: $section-height-sm; flex-direction: column; -webkit-flex-direction: column; }
	.sidebar 				{ padding: 4px; width: 100%; border-right: none; border-bottom: 1px solid $light-color; }
	.sidebar ul 			{ display: flex; display: -webkit-flex; justify-content: center; -webkit-justify-content: center; }
	.sidebar ul li a 		{ margin: 6px; max-width: 32px; }
	.content-section 		{ padding: 40px 15px 15px 15px; width: 100%;  }
/* -------- Login page ------- */
	.login-section 				{ min-height: initial; }
	.login-section  			{ flex-direction: column-reverse; -webkit-flex-direction: column-reverse; }
	.login-section > div 		{ width: 100%;}
	.login-section .form-block 	{ max-width: 95%; }
	.login-section .form-block 	{ padding: 24px 15px;}
	.form-group 				{ margin-bottom: 14px;}
	.form-group label 			{ margin-bottom: 8px;}
	.form-control 				{ padding: 8px 15px; }
	.login-section .theme-btn 	{ margin: 8px 0 16px;}
	.login-section .img-block 	{ padding-top: 62%; }
/* -------- Home page ------- */
	.home-table-wraper 					{ padding-left: 0px; }
	.scrollable-table 					{ height: calc(100vh - 132px) !important; }
	.home-table 						{ padding-left: 0px; font-size: 11px; line-height: 18px;}
	.scrollable-table .track-horizontal 		{ width: calc(100% - 40px) !important; }
	.home-table-wraper::before 			{ width: 100%; display: none; }
	.scrollable-table > div:first-child { margin-top: 0px; }
	.home-table .jcf-scrollbar-vertical { right: 8px;}
	.table-btns 						{ margin-bottom: 28px; transform: translateX(0%); -webkit-transform: translateX(0%);}
	.table-btns ~ .home-table 			{ margin-top: 29px; }
	.table-btns 		{ width: auto; top: 0px; display: flex; display: -webkit-flex; }
	.table-btns button 		{ height: 30px; width: 30px; }
	.theme-table li > div 				{ padding: 7px 8px; min-width: 160px; max-width: 160px; }
	.theme-table li > div:first-child 	{ padding-left: 7px; padding-right: 16px; min-width: 184px; max-width: 184px; }
	.theme-table .table-head > div 		{ padding-top: 20px; padding-bottom: 20px; }
	.theme-table .table-head img 		{ max-height: 50px;}
	.theme-table .table-head > div:first-child 	{ font-size: 14px; line-height: 20px; }
	.theme-table .input-row .form-control 		{ padding: 4px 0; font-size: 14px;}
	.theme-table .close-toggle 			{ height: 10px; width: 10px; right: 6px; }
	.theme-table .umbrella-icon,
	.theme-table .right-icon 			{ max-width: 16px; }
	.theme-table .umbrella-icon         { margin-left: -16px; }
	.theme-table .desc li, 
	.theme-table .desc p 				{ margin-bottom: 10px;}
	.cat-table-title { width: 260px; left: 10px; }
/* -------- Modal Block 7/8/3 screen------- */
	.modal-dialog 						{ max-width: 80%; }
	.modal-content 						{ padding: 15px 15px 10px;}
	.modal .action-button 				{ margin-bottom: 10px;}
	.modal .action-button button 		{ margin-left: 16px; }
	.add-provider-modal .logo-preview 	{ width: 110px; height: 110px; }
	.add-provider-modal .custom-file 	{ width: 110px;}
/* -------- Filter Section 5/6 screen  ------- */
	.full-section 						{ height: $section-height-sm; top: 58px;}
	.full-section .tab-section 			{ max-width: 580px;}
	.full-section .nav-pills a 			{ margin: 0 13px; padding: 12px 0 10px;}
	.full-section .tab-head .close-btn 	{ width: 18px;}
	.scrollable-list 					{ margin-bottom: 24px; height: 252px !important; }
	.check-list li 						{ padding: 10px 12px;}
	.check-list li .delete-icon 		{ margin-right: 36px; width: 18px; }
	.selected-list li 					{ padding: 10px 14px;}
	.selected-list li .delete-icon 		{ margin-right: 36px; width: 18px; }
/* -------- Edit Section 2/3 screen  ------- */
	.common-input-list li 				{ padding: 6px 10px;}
	.common-input-list li.condition-selected        { padding: 9px 14px; }
	.custom-dropdown .dropdown-toggle 	{ padding: 10px 16px; }
	.common-input-list li label 		{ padding: 1px 0;}
	.new-provider 						{ padding: 10px 28px 10px 14px;}
	.selected-edit-contract .dropdown-toggle 	{ padding: 10px 12px; white-space: normal;}
	.common-accordion .card-link 		{ padding: 10px 34px 10px 16px;}
	.common-accordion .inner-collapse .card-link { padding-left: 32px;}
	.common-accordion .card-link::after { height: 14px; width: 14px; right: 10px; }
	.custom-control .custom-control-label::before 			{ margin-right: 4px; height: 18px; width: 18px;}
	.common-accordion .scrollable-inner-accordion			{ margin-bottom: 24px; height: 450px !important;}
	.common-accordion .inner-collapse .card-header .custom-control 	{ left: 10px; }
	.common-accordion .inner-accordion .common-input-list li label { width: 65%; }
	.inner-accordion .common-input-list .form-control 		{ width: 35%; }
	.inner-accordion .common-input-list textarea.form-control 	{ width: 100%;}
/* -------- User Section screen  ------- */
	.user-list li 						{ padding: 11px 0; }
/* -------- Provider Section screen  ------- */
	.provider-logo img					{ width: 100px; padding: 8px; }
/* -------- EVENTS COVERED BY CATEGORY  ------- */
	.cat-table-title { margin-left: 0; }
	.cat-table .theme-table .toggle-row li .condition-list > li { padding: 8px 12px; }
	.cat-table .theme-table .tiles-bg .condition-toggle-desc { margin-top: 6px; padding: 8px 10px;}
}

@media (max-width: 575px) {
	.succes-btn             			{ padding: 7px 14px 6px; min-width: 110px; }
/* -------- Custom Elemet Style ------- */
	.track-vertical 					{ width: 5px !important; right: 10px;}
/* -------- Filter Section 5/6 screen  ------- */
	.full-section .tab-head 			{ padding: 0 15px 0 5px;}
	.full-section .nav-pills a 			{ margin: 0 8px; padding: 10px 0 8px; }
	.full-section .tab-head .close-btn 	{ width: 16px; }
	.tab-content 						{ padding: 18px 16px; }
	.scrollable-list 					{ height: 220px !important; }
	.check-list li 						{ padding: 8px 12px; }
	.custom-control .custom-control-label 	{ padding-left: 26px; }
	.check-list li .delete-icon 		{ margin-right: 12px; width: 15px; }
	.selected-list li 					{ padding: 8px 14px;}
	.selected-list li .delete-icon 		{ margin-right: 12px; width: 15px; }
/* -------- Modal Block 7/8/3 screen------- */
	.modal-dialog 						{ max-width: 90%; }
/* -------- Edit Section 2/3 screen  ------- */
	.common-accordion .inner-collapse 	{ padding: 15px 15px 15px 5px; }
	.scrollable-simple-list 			{ height: 180px !important;}
	.simple-list li 					{ padding: 8px 15px;}
/* -------- User Section screen  ------- */
	.user-list li 						{ padding: 8px 0; }
/* -------- EVENTS COVERED BY CATEGORY  ------- */
	.cat-table .theme-table .toggle-row li .condition-list > li { padding: 8px 10px; }
}

@media (max-width: 359px) {
	.home-table 						{ font-size: 10px; line-height: 17px;}
	.theme-table li > div 				{ padding: 6px 7px; min-width: 142px; max-width: 142px; }
	.theme-table li > div:first-child 	{ padding-left: 6px; padding-right: 16px; min-width: 152px; max-width: 152px; }
/* -------- Filter Section 5/6 screen  ------- */
	.full-section .tab-head 			{ padding: 0 12px 0 4px;}
	.full-section .nav-pills a 			{ margin: 0 5px; font-size: 11px; }
/* -------- Edit Section 2/3 screen  ------- */
	.common-accordion .card-link 		{ padding: 8px 26px 8px 10px; }
	.common-accordion .card-link::after { height: 13px; width: 13px; right: 10px; }
	
}

