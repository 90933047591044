
.record-main.csv-main {
  .record-sidebar { width: 360px; background: #fff;
    h4 { margin-bottom: 20px; font-weight: 500; }
    .title { font-weight: 500;
      img  { max-width: 24px; @include flex-shrink(0);}
    }
    .item { margin-bottom: 22px; padding-bottom: 24px; border-bottom: 2px solid $light-bg;
      &:last-child { margin-bottom: 0; padding-bottom: 0; border: none;}
    }
    button { margin-bottom: 4px; width: 100%; padding: 8px 16px; background: #e1ece9; color: rgba(41, 41, 41,0.20); font-weight: 500; border: none; text-transform: uppercase; position: relative;
      &.active { background: $theme-color; color: #fff; }
      input[type="file"] { opacity: 0; position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 5; cursor: pointer; }
    }
    .file-name img { margin-right: 8px;}
  }
  .record-section { width: calc(100% - 360px); 
    .step-head { margin: 0; padding: 8px 16px; background: $dark-bg; color: #fff; text-transform: uppercase;}
  }
  .border-head .step-head:first-child,
  .common-form .form-group label { width: 230px; }
  .common-form{
    .custom-dropdown {
      .dropdown-toggle { text-transform: none;}
      .dropdown-menu { min-width: 100%;}
    } 
  }
  .border-head .step-head:first-child   { @include flex-shrink(0); }
  .border-head .step-head:last-child    { @include flex-grow(1); border-left: 2px solid #fff; }

}

@media (max-width: 1199px) {
  .record-main.csv-main {
    .record-sidebar { width: 320px;}
    .record-section { width: calc(100% - 320px); }
    .border-head .step-head:first-child,
    .common-form .form-group label { width: 210px; }
  }
}
@media (max-width: 991px) {
  .record-main.csv-main {
    .record-sidebar { width: 240px;}
    .record-section { width: calc(100% - 240px); }
    .border-head .step-head:first-child,
    .common-form .form-group label { width: 200px; }
  }
}

@media (max-width: 767px) {
  .record-main.csv-main {
    .record-sidebar { width: 100%;
      h4 { margin-bottom: 12px;}
      .title { margin-bottom: 8px;
        img  { max-width: 18px; }
      }
      .item { margin-bottom: 12px; padding-bottom: 14px; }
      button { margin-bottom: 3px; padding: 6px 16px; }
    }
    .record-section { width: 100%; }
  }
}

@media (max-width: 480px) {
  .record-main.csv-main {
    .common-form .form-group        { font-size: 10px; }
    .border-head .step-head:first-child,
    .common-form .form-group label  { width: 154px; }
    .common-form .form-group label  { padding: 5px 8px; }
    .common-form .form-group .form-control { padding: 7px 12px 6px; width: calc(100% - 154px); }
  }
}